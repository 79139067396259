import React, { Fragment, useEffect } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import _ from "lodash";
import styled, { createGlobalStyle } from "styled-components";

export const SearchInput = ({
  handleSearch,
  options,
  register,
  name,
  Controller,
  control,
  defaultValue,
  prefillData,
  selected,
  allowNew,
  newSelectionPrefix,
  multiple,
  isEmail,
}) => {
  const formatted_options = !_.isEmpty(options) ? options : [];
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  return (
    <>
      <Controller
        as={
          <AsyncTypeahead
            // style={emailStyle}
            // className="emailStyle"
            // className={isEmail ? "multiEmails" : ""}
            // onChange={e => handleInput(e)}
            // onKeyDown={e => handleInput(e)}
            // onInputChange={e => handleInput(e)}
            allowNew={allowNew}
            filterBy={filterBy}
            newSelectionPrefix={newSelectionPrefix}
            id="custom-selections-example"
            name={name}
            ref={register}
            labelKey="name"
            minLength={3}
            onSearch={handleSearch}
            clearButton={false}
            defaultInputValue={selected ? selected : ""}
            // isLoading={!_.isEmpty(options) ? false : true}
            options={formatted_options}
            placeholder={isEmail ? "Email" : "Search financer..."}
            multiple={multiple}
            renderMenuItemChildren={(option, props) => (
              <Fragment>
                <span style={{ fontSize: "12px" }}>
                  {(option?.name).trim()}
                </span>
              </Fragment>
            )}
          />
        }
        defaultValue={defaultValue}
        name={name}
        control={control}
      />
      {/* <GlobalStyle disabledBackdrop={false} /> */}
    </>
  );
};

const GlobalStyle = createGlobalStyle`
// body {
// 	.emailStyle{
//     border-radius: 10px;
//     color: "#333";
//     padding: 20px 8px 5px 20px;
//     font-size: 14px;
//     border: 1px solid #e3e4e8;
//     height: 50px;
//   }
//   .multiEmails .rbt-token-removeable {
//     background: ${({ theme }) => theme.floatButton?.floatColor || "#bdd400"};
//     color: #fff;
//   }
// }
`;
