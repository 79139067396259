import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { Button as Btn, BackButton, Loader } from "components";
import { useHistory, useLocation } from "react-router";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
	Type,
	set_temp_data,
	SaveQuoteData,
	clear,
} from "modules/Home/home.slice";
import _ from "lodash";
import styled from 'styled-components';
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";

const ls = new SecureLS();
const ThemeLS = ls.get("themeData");
const Theme = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;

// validation schema
const yupValidate = yup.object({
	ownerTypeId: yup.string().required("Type is required"),
});

export const JourneyType = ({ enquiry_id, type: routeType, token }) => {
	const dispatch = useDispatch();
	const { type, temp_data, loading, saveQuoteData } = useSelector(
		(state) => state.home
	);
	const history = useHistory();

	/*---------------- back button---------------------*/
	const back = () => {
		history.push(
			`/${routeType}/vehicle-details?enquiry_id=${
				temp_data?.enquiry_id || enquiry_id
			}${token ? `&token=${token}` : ``}`
		);
	};
	/*----------x----- back button-------x-------------*/

	const { handleSubmit, register, errors, setValue, watch } = useForm({
		resolver: yupResolver(yupValidate),
		mode: "all",
		reValidateMode: "onBlur",
	});

	let selected = watch("ownerTypeId") || temp_data?.ownerTypeId;

	//type load
	useEffect(() => {
		dispatch(Type());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//onSuccess
	useEffect(() => {
		if (saveQuoteData) {
			history.push(
				`/${routeType}/quotes?enquiry_id=${temp_data?.enquiry_id || enquiry_id}${
					token ? `&token=${token}` : ``
				}`
			);
		}

		return () => {
			dispatch(clear("saveQuoteData"));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [saveQuoteData]);

	const onSubmit = (ownerTypeId) => {
		dispatch(set_temp_data({ ownerTypeId: ownerTypeId }));
		dispatch(
			SaveQuoteData({
				stage: "10",
				enquiryId: enquiry_id,
				userProductJourneyId: enquiry_id,
				vehicleOwnerType: Number(ownerTypeId) === 1 ? "I" : "C",
				vehicleRegisterDate: temp_data?.year,
				manufactureYear: temp_data?.manfDate,
				rtoNumber: temp_data?.rtoNumber,
				rto: temp_data?.rtoNumber,
				vehicleRegisterAt: temp_data?.rtoNumber,
				version: temp_data?.versionId,
				versionName: temp_data?.versionName,
				fuelType: temp_data?.fuel,
				vehicleLpgCngKitValue: temp_data?.kit_val ? temp_data?.kit_val : null,
				model: temp_data?.modelId,
				modelName: temp_data?.modelName,
				manfactureId: temp_data?.manfId,
				manfactureName: temp_data?.manfName,
			})
		);
	};

	return (
		<>
			{!loading ? (
				<>
					<div className="backBtn" style={{ paddingBottom: "30px" }}>
						<BackButton type="button" onClick={back} style={{ marginTop: "-20px" }}>
							<svg xmlns="http://www.w3.org/2000/svg" className="" viewBox="0 0 24 24">
								<path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z" />
								<path d="M0 0h24v24H0z" fill="none" />
							</svg>
							<text style={{ color: "black" }}>Back</text>
						</BackButton>
					</div>
					<div className="my-4 mx-auto ElemFade">
						<Row className="text-center w-100 mx-auto">
							<div className="mt-0 d-flex flex-column justify-content-center w-100">
								<StyledH3 className="text-center w-100">Choose the Journey Type</StyledH3>
							</div>
						</Row>
						<Row className="d-flex justify-content-center w-100 mt-5 mx-auto">
							{type?.map(({ ownerType, ownerTypeId }) => (
								<Col
									xs="6"
									sm="6"
									md="6"
									lg="6"
									xl="6"
									className="d-flex justify-content-center my-2 mx-auto w-100"
								>
									<Button
										variant={
											Number(selected) === Number(ownerTypeId)
												? Theme?.VehicleType?.buttonVariant || "success"
												: Theme?.VehicleType?.outlineVariant || "outline-success"
										}
										onClick={() => {
											setValue("ownerTypeId", ownerTypeId);
											onSubmit(ownerTypeId);
										}}
										className="py-3 px-4"
									>
										<strong style={{ letterSpacing: "1px" }}>
											<svg
												className="bi bi-person-fill"
												viewBox="0 0 16 16"
												fill="currentColor"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 100-6 3 3 0 000 6z" />
											</svg>
											{ownerType}
										</strong>
									</Button>
								</Col>
							))}
							<input ref={register} name="ownerTypeId" type="hidden" />
						</Row>
					</div>
				</>
			) : (
				<Loader />
			)}
		</>
	);
};



const StyledH3 = styled.h3`
	color: rgb(74, 74, 74);
	font-size: 30px;
	@media (max-width: 767px) {
		font-size: 25px;
	}
	`